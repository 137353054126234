<template>
  <div>
    <header-slot>
      <template v-if="$route.meta.isActive === 1" #actions>
        <b-row class="justify-content-end mr-1">
          <b-button variant="outline-danger" :to="'/users/session/0'">
            <tabler-icon icon="HistoryIcon" />
            History Access
          </b-button>
          <b-button variant="success" :to="'/users/create'" class="ml-1">
            <feather-icon icon="PlusIcon" />
            Create
          </b-button>
        </b-row>
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'users-active' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >Active</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'users-inactive' }"
        exact
        exact-active-class="active"
        :link-classes="[bgTabsNavs]"
        >Inactive</b-nav-item
      >
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {};
</script>

<style>
</style>
